import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./simple-lightbox");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  // House widget
  $('.house-widget').on('click', function () {
    let n = $(this).data('n');
    $('.house-widget').removeClass('active');
    $('.flat-list__wrapper .flatItem').removeClass('active');
    $(this).addClass('active');
    $('#l' + n).addClass('active');
    $('#flat' + n).css('background-color', '#F4F6F8');
    $([document.documentElement, document.body]).animate({
      scrollTop: $('#l' + n).offset().top - 150
    }, 500);
  });

  if( $(window).width() > 991 ) {
    $('.house-widget').hover(function () {
      $(".flat-list__tooltip").addClass('active');
      $(".flat-list__tooltip").html($(this).attr('title'));
    }, function () {
      $(".flat-list__tooltip").removeClass('active');
    });
    // rodzic nie może być relative
    $( document ).on( "mousemove", function( event ) {
      $( ".flat-list__tooltip" ).css({
        "left" : event.pageX,
        "top" : event.pageY - 70
      });
    });
  }

  // Load virtual walk on click
  $('.v-walk__box').click(function(){
    $('.v-walk__box iframe').attr('src', '/vr/index.html');
    $('.v-walk__box .start').fadeOut();
    $('.v-walk__box iframe').fadeIn();
  });


// 




  // Parallax
  var winScrollTop = 0;

  $.fn.is_on_screen = function () {
    var win = $(window);
    var viewport = {
      top: win.scrollTop(),
      left: win.scrollLeft()
    }; //viewport.right = viewport.left + win.width();

    viewport.bottom = viewport.top + win.height();
    var bounds = this.offset(); //bounds.right = bounds.left + this.outerWidth();

    bounds.bottom = bounds.top + this.outerHeight();
    return !(viewport.bottom < bounds.top || viewport.top > bounds.bottom);
  };

  function parallax() {
    var scrolled = $(window).scrollTop();
    $('.parallax_scroll').each(function () {
      if ($(this).is_on_screen()) {
        var firstTop = $(this).offset().top;
        var $span = $(this);
        var moveTop = (firstTop - winScrollTop) * 0.1; //speed;

        $span.css("transform", "translateY(" + -moveTop + "px)");
      }
    });
  }

  $(window).scroll(function (e) {
    winScrollTop = $(this).scrollTop();
    parallax();
  });


  // Parallax BG

  $(document).ready(function(){
    // Cache the Window object
    var $window = $(window);
  
    $('[data-parallax]').each(function(){
      var $image = $(this); // assigning the object
    
        $(window).scroll(function() {
    
          if ($image.data('parallax-setting') == 'down')  {
            var yPos = ($window.scrollTop() / $image.data('speed'));
          } else {
            var yPos = -($window.scrollTop() / $image.data('speed'));
          }
    
          // Scroll the background at var speed
          // the yPos is a negative value because we're scrolling it UP!								
          //var yPos = -($window.scrollTop() / $image.data('speed'));
          // Put together our final background position
          var coords = '50% '+ yPos + 'px';
    
          // Move the background
          $image.css( "background-position", coords );
    
        }); // window scroll Ends
      });
  });



});

